import React, { useContext, useEffect, useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import { Table, Card, ProgressIndicator, TableColumns } from '@veneer/core'
import { useParams } from 'react-router-dom'
import { useReports } from '../../../../hooks/useReports'
import { SearchList } from '../../../SearchList'
import { ReportsFilterButton } from '../../../ReportsFilterButton'
import { ReportDetailsCard } from '../../Common/ReportDetailsCard'
import { ReportListTableStatus } from '../../Common/ReportListTableStatus'
import { Footer } from '../../Common/Footer'
import { getDateAndTime, setBreadcrumbs } from '../../../../utils/commonMethods'
import GeneralContext from '../../../../contexts/GeneralContext'
import * as Wrapper from './styles'
import {
  TablePaginationText,
  // ReportDetailsBreadcrumData,
  Tablei18nText
} from '../../../../utils/consts'
import { selectedGroupsFormat } from '../../../../utils/genericMethods'
import { HighlightText } from '../../../HighlightText'
import {
  isData,
  sort,
  getAssessmentStatusName,
  getAssessmentStatus
} from '../../Common/ReportUtils'

export const DevicesAssessed = () => {
  const { t } = useI18n()
  const { navigation, stack, breadcrumbs, locale } = useContext(GeneralContext)
  const { downLoadingReports, fetchReportsByID } = useReports()
  const { reportId, reportCategory, reportType } = useParams()
  const reportMetaData = navigation?.location?.state || ''
  const [reportDetails, setReportDetails] = useState(null)
  const [reportDetailsLoading, setReportDetailsLoading] = useState(true)
  const [loading, setLoading] = useState(true)
  const [reportDataState, setReportDataState] = useState<any>([])
  const [tableData, setTableData] = useState([])
  const [customerName, setcustomerName] = React.useState('--')
  const [orderType, setOrderType] = React.useState<'ascending' | 'descending'>(
    'ascending'
  )
  const [currentPage, setCurrentPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(4)
  const [collectionInfo, setCollectionInfo] = useState([])
  const [orderBy, setOrderBy] = useState<string>('modelname')
  const [searchText, setSearchText] = useState('')
  const [order, setOrder] = React.useState([
    'assessmentStatus',
    'modelname',
    'serialNumber',
    'group',
    'dateAssessed'
  ])

  const reloadDeviceList = () => {
    downLoadingReports(reportId, stack)
      .then((response) => {
        JSON.parse(response[0])?.collectionInfo &&
          setCollectionInfo(JSON.parse(response)?.collectionInfo)
        JSON.parse(response[0])?.customerName &&
          setcustomerName(JSON.parse(response)?.customerName)
        const reportData =
          JSON.parse(response[0]).deviceList ||
          response.map((element) => {
            return JSON.parse(element)
          })
        if (isData(reportData)) {
          const sortedArray = sort(reportData, orderBy, orderType)
          setTableData(createTableData(sortedArray))
          setReportDataState(reportData)
        } else {
          setTableData([])
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const reloadReportDetails = () => {
    fetchReportsByID(reportId)
      .then((response) => {
        setReportDetails(response)
        setBreadcrumbs(breadcrumbs, navigation, t, {
          name: response.fileName || response.reportName
        })
        // setBreadcrumb(
        //   ReportDetailsBreadcrumData(response.fileName || response.reportName)
        // )
      })
      .catch((e) => {
        console.log(e)
        setReportDetails({})
      })
      .finally(() => {
        setReportDetailsLoading(false)
      })
  }

  const createTableData = (devices, searchValue = '') => {
    return devices.map((device) => {
      let { statusName, statusColor } = getAssessmentStatus(
        device?.assessmentStatus?.result,
        t
      )

      return {
        assessmentStatus: (
          <ReportListTableStatus
            statusName={statusName}
            statusColor={statusColor}
            searchItem={searchValue}
          />
        ),
        modelname: (
          <Wrapper.RedirectLink
            data-testid="device_Assessed_List_Modelname"
            id="device_Assessed_List_Modelname"
            onClick={() => {
              device?.detailedReportId &&
                navigation.push(
                  `/reports/${reportCategory}/${reportType}/${reportId}/${device?.deviceDetails?.deviceId}/${device?.detailedReportId}`,
                  reportMetaData
                )
            }}
          >
            <a>
              <HighlightText
                value={device?.deviceDetails?.modelname}
                searchItem={searchValue}
              />
            </a>
          </Wrapper.RedirectLink>
        ),
        serialNumber: (
          <HighlightText
            value={device?.deviceDetails?.serialNumber}
            searchItem={searchValue}
          />
        ),
        group: device?.collectionInfo ? (
          <HighlightText
            value={device?.collectionInfo[0]?.collectionName}
            searchItem={searchValue}
          />
        ) : (
          ''
        ),
        // firmwareSecurity: 'Update available',
        dateAssessed: (
          <HighlightText
            value={getDateAndTime(
              device?.assessmentStatus?.lastAssessedTime || null,
              locale
            )}
            searchItem={searchValue}
          />
        )
      }
    })
  }

  const createExportTableData = (devices) => {
    return devices.map((device) => {
      let statusName: string = getAssessmentStatusName(
        device?.assessmentStatus?.result,
        t
      )

      return {
        assessmentStatus: statusName || '',
        modelname: device?.deviceDetails?.modelname || '',
        serialNumber: device?.deviceDetails?.serialNumber || '',
        group: device?.collectionInfo
          ? device?.collectionInfo[0]?.collectionName
          : '',
        // firmwareSecurity: 'Update available',
        dateAssessed:
          getDateAndTime(
            device?.assessmentStatus?.lastAssessedTime || null,
            locale
          ) || ''
      }
    })
  }

  useEffect(() => {
    reloadDeviceList()
    reloadReportDetails()
  }, [reportId])

  useEffect(() => {
    if (searchText === '') {
      handleSort(null, { orderBy, orderType })
    }
  }, [searchText])

  const totalItems = tableData.length

  const headers = [
    {
      key: 'assessmentStatus',
      label: t(
        'ecp-global-reports.tableHeader.assessmentStatus',
        'Assessment Status'
      )
    },
    {
      key: 'modelname',
      label: t('ecp-global-reports.tableHeader.modelName', 'Model Name')
    },
    {
      key: 'serialNumber',
      label: t('ecp-global-reports.tableHeader.serialNumber', 'Serial Number')
    },
    {
      key: 'group',
      label: t('ecp-global-reports.tableHeader.group', 'Group')
    },
    {
      key: 'dateAssessed',
      label: t('ecp-global-reports.tableHeader.dateAssessed', 'Date Run')
    }
  ]

  const handleSort = (_, { id = orderBy, type = orderType }: any) => {
    let searchResult
    let sortedArray
    if (searchText) {
      searchResult = getSearchResult(searchText)
      sortedArray = sort(searchResult, id, type)
      setTableData(createTableData(sortedArray, searchText))
    } else {
      sortedArray = sort(reportDataState, id, type)
      setTableData(createTableData(sortedArray))
    }
    setOrderBy(id)
    setOrderType(type)
  }

  const handlePageChange = (page) => setCurrentPage(page)

  const handlePageSizeChange = (event, option) => {
    setPageSize(option.value)
  }

  const finalData = tableData.slice(
    (currentPage - 1) * pageSize,
    (currentPage - 1) * pageSize + pageSize
  )

  const deviceGroupData = collectionInfo?.length
    ? selectedGroupsFormat(collectionInfo, t)
    : null

  const getSearchResult = (searchValue) => {
    return reportDataState.filter((item) => {
      return (
        getAssessmentStatusName(item?.assessmentStatus?.result, t)
          .toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        // getDateAndTime(item?.assessmentStatus?.lastAssessedTime || null)
        //   ?.toLowerCase()
        //   .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.collectionInfo[0]?.collectionName
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.deviceDetails?.modelname
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1 ||
        item?.deviceDetails?.serialNumber
          ?.toLowerCase()
          .indexOf(searchValue.toLowerCase()) > -1
      )
    })
  }

  const handleSearch = (searchValue) => {
    if (searchValue) {
      let searchResult = getSearchResult(searchValue)
      setTableData(createTableData(searchResult, searchValue))
    } else {
      setTableData(createTableData(reportDataState, searchValue))
    }
  }

  const handleOnColumnReorder = (newOrder) => {
    setOrder(newOrder)
  }

  return (
    <Wrapper.RDContainer>
      {reportDetailsLoading || loading ? (
        <Card
          data-testid="reports_Loading_card"
          border="dropShadow"
          content={
            (
              <ProgressIndicator
                data-testid="reports_Progress_Indicator"
                style={{ padding: '3% 50%' }}
                appearance={'circular'}
              />
            ) as any
          }
        />
      ) : !loading ? (
        <ReportDetailsCard
          data-testid="device_Assessed_List_Top_Card"
          reportDetails={reportDetails}
          deviceGroupData={deviceGroupData}
          customerName={customerName}
          headers={headers}
          exportData={createExportTableData(reportDataState)}
          imgDisplay={false}
        />
      ) : (
        ''
      )}
      <Wrapper.ReportListTable data-testid="device_Assessed_Table_List">
        <Table
          columnReorder={true}
          onColumnReorder={handleOnColumnReorder}
          actionArea={
            <Wrapper.ReportListSubHeader>
              <Wrapper.ReportListSubHeaderContainer data-testid="report_Header_Left_List">
                <SearchList
                  data-testid="reports_Search_Filter_Box"
                  handleSearch={handleSearch}
                  setSearchText={setSearchText}
                  setCurrentPage={setCurrentPage}
                  searchText={searchText}
                  isLoading={loading}
                  errorDisabled={reportDataState.length < 1}
                />
                <ReportsFilterButton
                  data-testid="reports_Filter_Button"
                  isLoading={loading}
                  errorDisabled={reportDataState.length < 1}
                />
              </Wrapper.ReportListSubHeaderContainer>
            </Wrapper.ReportListSubHeader>
          }
          data-testid="device_Assessed_Table_List_Component"
          i18n={Tablei18nText(t)}
          columns={
            [
              {
                id: 'assessmentStatus',
                label: t(
                  'ecp-global-reports.tableHeader.assessmentStatus',
                  'Assessment Status'
                ),
                sortable: false
              },
              {
                id: 'modelname',
                label: t(
                  'ecp-global-reports.tableHeader.modelName',
                  'Model Name'
                ),
                sortable: finalData?.length > 1 ? true : false,
                required: true
              },
              {
                id: 'serialNumber',
                label: t(
                  'ecp-global-reports.tableHeader.serialNumber',
                  'Serial Number'
                ),
                sortable: false
              },
              {
                id: 'group',
                label: t('ecp-global-reports.tableHeader.group', 'Group'),
                sortable: false
              },
              // {
              //   : 'firmwareSecurity',
              //   label: t('ecp-global-reports.tableHeader.firmwareSecurity', 'Firmware Security')
              // },
              {
                id: 'dateAssessed',
                label: t(
                  'ecp-global-reports.tableHeader.dateAssessed',
                  'Date Assessed'
                ),
                sortable: false
              }
            ] as TableColumns[]
          }
          data={finalData}
          onSort={handleSort}
          loadingDataLength={3}
          loading={loading}
          preferences={{
            sortBy: {
              id: orderBy || 'modelname',
              type: orderType || 'ascending'
            },
            width: [
              { columnId: 'assessmentStatus', width: 180 },
              { columnId: 'modelName', width: 300 },
              { columnId: 'serialNumber', width: 150 },
              { columnId: 'group', width: 170 },
              { columnId: 'dateAssessed', width: 170 }
            ],
            defaultOrder: [
              'assessmentStatus',
              'modelname',
              'serialNumber',
              'group',
              'dateAssessed'
            ],
            order
          }}
          pagination={{
            currentPage,
            onPageChange: handlePageChange,
            onPageSizeChange: handlePageSizeChange,
            pageSize,
            pageSizeOptions: [
              { value: 2 },
              { value: 4 },
              { value: 8 },
              { value: 10 }
            ],
            i18n: TablePaginationText(t, currentPage, totalItems),
            totalItems
          }}
        />
      </Wrapper.ReportListTable>

      <Wrapper.RDSubContainer data-testid="reports_Footer">
        <Footer data-testid="reports_Footer_Info" />
      </Wrapper.RDSubContainer>
    </Wrapper.RDContainer>
  )
}
