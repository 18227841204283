import { RadioButtons } from '@veneer/core'
import styled from 'styled-components'
// import tokens from "@veneer/tokens";

export const SectionTitle = styled.p`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`

export const ScheduleOptionRadioButtons = styled(RadioButtons)`
  margin-top: 16px;
  margin-bottom: 16px !important;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`

export const SectionTitleDivider = styled.div`
  border-bottom: 1px solid #ebe7e7;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 4px 0px;
`

export const DeviceGroupTimeRadioButton = styled.div`
  margin-left: 30px;
  margin-top: 18px;
`

export const SectionContainer = styled.div`
  margin-top: 40px;
`
export const scheduleTimeContainer = styled.div`
 margin-left: 32px;
@media only screen and (max-width: 550px) {
  width: 50%
  margin-left: 0px;
}
  @media only screen and (min-width: 551px) {
  margin-left: 28px;
}
`