//Component for all Export to XLSX functionality
import React, { useContext, useEffect, useState } from 'react'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import GeneralContext from '../../contexts/GeneralContext'
import { useReports } from '../../hooks/useReports'
import { getDateAndTime } from '../../utils/commonMethods'
import { useI18n } from '@jarvis/react-portal-addons'
import { useToast } from '@veneer/core'

export const ExcelDownload = (props) => {
  const { fileName, type, reportId } = props
  const { stack, locale } = useContext(GeneralContext)
  const { t } = useI18n()
  const { downLoadingReports } = useReports()
  const [data, setData] = useState(props.data || [])
  const [headers, setHeaders] = useState(props.headers || [])
  const _export = React.createRef<ExcelExport>()
  const toast = useToast()
  const { addToast } = toast

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(
        data,
        headers.map((_item) => {
          return { field: _item.key, title: _item.label }
        })
      )
    }
  }

  const isData = (devices) => {
    if (devices.length === 1) {
      const device = devices[0]
      return (
        device?.assessmentStatus?.result !== null &&
        device?.deviceDetails?.modelname !== null &&
        device?.deviceDetails?.serialNumber !== null
      )
    }
    return devices.length > 0
  }

  const policyDeviceStatusExport = (status, CatId) => {
    var innerItemData = []

    status[CatId].forEach((item) => {
      item.deviceStatus.forEach((deviceStatus) => {
        const riskid =
          deviceStatus.assessmentStatus !== 'passed'
            ? deviceStatus.assessmentStatus + 'Risk'
            : deviceStatus.assessmentStatus
        innerItemData.push({
          policyName: t(`ecp-global-reports.tableHeader.${CatId}`, ''),
          risk: t(`ecp-global-reports.risks.${riskid}`, ''),
          itemName:
            item?.settingId &&
            t(`ecp-global-reports.settings.${item.settingId}.name`, ''),
          count: deviceStatus.count
        })
      })
    })
    return innerItemData
  }

  const _fetchData = () => {
    downLoadingReports(reportId, stack)
      .then((response) => {
        let reportData = []

        if (response.length > 0) {
          switch (type) {
            case 'policyItemsAssessed':
              reportData = response
              break
            case 'jobSummary':
              reportData = JSON.parse(response[0])?.jobs
              break
            default:
              reportData =
                JSON.parse(response[0])?.deviceList || response.map(JSON.parse)
              break
          }
        }

        if (isData(reportData)) {
          switch (type) {
            case 'jobSummary': {
              const headers = [
                {
                  key: 'jobName',
                  label: t('ecp-global-reports.tableHeader.jobName', 'Job Name')
                },
                {
                  key: 'deviceDetail',
                  label: t(
                    'ecp-global-reports.tableHeader.deviceDetail',
                    'Device Detail'
                  )
                },
                {
                  key: 'date',
                  label: t('ecp-global-reports.tableHeader.date', 'Date')
                },
                {
                  key: 'status',
                  label: t('ecp-global-reports.status', 'Status')
                },
                {
                  key: 'copies',
                  label: t('ecp-global-reports.tableHeader.copies', 'Copies')
                }
              ]
              setData(
                reportData?.map((job) => {
                  let statusName: string
                  switch (job?.status) {
                    case 'failed':
                      statusName = t(
                        'ecp-global-reports.statusList.failed',
                        'Failed'
                      )
                      break
                    case 'cancelled':
                      statusName = t(
                        'ecp-global-reports.jobHistoryExecutiveSummary.cancelled',
                        'Cancelled'
                      )
                      break
                    case 'success':
                      statusName = t(
                        'ecp-global-reports.statusList.completed',
                        'Completed'
                      )
                      break
                    default:
                      statusName = ''
                      break
                  }
                  return {
                    jobName: job?.jobName,
                    deviceDetail: job?.modelName,
                    date: getDateAndTime(
                      job?.jobCompletionTime || null,
                      locale
                    ),
                    status: statusName,
                    copies: job?.copiesCount
                  }
                })
              )

              setHeaders(headers)
              break
            }
            case 'policyItemsAssessed': {
              const headers = [
                {
                  key: 'policyName',
                  label: t('ecp-global-reports.tableHeader.policy', 'Policy')
                },
                {
                  key: 'risk',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'itemName',
                  label: 'Item'
                },
                {
                  key: 'count',
                  label: t('ecp-global-reports.tableHeader.count', 'Count')
                }
              ]
              const dataExport = {
                reportDataExport: reportData.map((element) => {
                  return JSON.parse(element)
                })
              }
              const settings = dataExport.reportDataExport
              var policyData = []

              const data = settings[0]?.policySettingsReportList || settings
              data.map((policy) => {
                var temp: any = []
                const CatId: any = Object.keys(policy)[0]
                temp = policyDeviceStatusExport(policy, CatId)
                temp.map((itemData) => policyData.push(itemData))
              })
              setData(policyData)
              setHeaders(headers)
              break
            }
            case 'devicesRecommendationSummary': {
              const headers = [
                {
                  key: 'assessmentStatus',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'modelName',
                  label: t(
                    'ecp-global-reports.tableHeader.modelName',
                    'Model Name'
                  )
                },
                {
                  key: 'serialNumber',
                  label: t(
                    'ecp-global-reports.tableHeader.serialNumber',
                    'Serial Number'
                  )
                },
                {
                  key: 'group',
                  label: t('ecp-global-reports.tableHeader.group', 'Group')
                },
                {
                  key: 'recommendations',
                  label: t(
                    'ecp-global-reports.reportsType.devicesRecommendationSummary',
                    'Recommendations'
                  )
                },
                {
                  key: 'dateAssessed',
                  label: t(
                    'ecp-global-reports.tableHeader.dateAssessed',
                    'Date Assessed'
                  )
                }
              ]
              setData(
                reportData.map((device) => {
                  let statusName: string
                  switch (device?.assessmentStatus?.result) {
                    case 'lowRisk':
                      statusName = t(
                        'ecp-global-reports.risks.lowRisk',
                        'Low Risk'
                      )

                      break
                    case 'mediumRisk':
                      statusName = t(
                        'ecp-global-reports.risks.mediumRisk',
                        'Medium Risk'
                      )

                      break
                    case 'highRisk':
                      statusName = t(
                        'ecp-global-reports.risks.highRisk',
                        'High Risk'
                      )

                      break
                    case 'passed':
                      statusName = t(
                        `ecp-global-reports.risks.passed`,
                        'Passed'
                      )

                      break
                  }
                  return {
                    assessmentStatus: statusName || '',
                    modelName: device?.deviceDetails?.modelname || '',
                    serialNumber: device?.deviceDetails?.serialNumber || '',
                    group: device?.collectionInfo
                      ? device?.collectionInfo[0]?.collectionName
                      : '',
                    recommendations:
                      device?.assessmentStatus?.highRiskCount +
                      device?.assessmentStatus?.lowRiskCount +
                      device?.assessmentStatus?.mediumRiskCount,
                    dateAssessed:
                      getDateAndTime(
                        device?.assessmentStatus?.lastAssessedTime || null,
                        locale
                      ) || ''
                  }
                })
              )
              setHeaders(headers)
              break
            }
            case 'devicesRemediationSummary': {
              const headers = [
                {
                  key: 'assessmentStatus',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'modelName',
                  label: t(
                    'ecp-global-reports.tableHeader.modelName',
                    'Model Name'
                  )
                },
                {
                  key: 'serialNumber',
                  label: t(
                    'ecp-global-reports.tableHeader.serialNumber',
                    'Serial Number'
                  )
                },
                {
                  key: 'group',
                  label: t('ecp-global-reports.tableHeader.group', 'Group')
                },
                {
                  key: 'lastRemediated',
                  label: t(
                    'ecp-global-reports.tableHeader.devicesRemediationSummary',
                    'Date Run'
                  )
                }
              ]
              setData(
                reportData.map((device) => {
                  let statusName: string
                  switch (device?.assessmentStatus?.result) {
                    case 'lowRisk':
                      statusName = t(
                        'ecp-global-reports.risks.lowRisk',
                        'Low Risk'
                      )

                      break
                    case 'mediumRisk':
                      statusName = t(
                        'ecp-global-reports.risks.mediumRisk',
                        'Medium Risk'
                      )

                      break
                    case 'highRisk':
                      statusName = t(
                        'ecp-global-reports.risks.highRisk',
                        'High Risk'
                      )

                      break
                    case 'passed':
                      statusName = t(
                        `ecp-global-reports.risks.passed`,
                        'Passed'
                      )

                      break
                  }
                  return {
                    assessmentStatus: statusName || '',
                    modelName: device?.deviceDetails?.modelname || '',
                    serialNumber: device?.deviceDetails?.serialNumber || '',
                    group: device?.collectionInfo
                      ? device?.collectionInfo[0]?.collectionName
                      : '',
                    firmwareSecurity: 'Update available',
                    lastRemediated:
                      getDateAndTime(
                        device?.assessmentStatus?.lastRemediatonTimeStamp ||
                          null,
                        locale
                      ) || ''
                  }
                })
              )
              setHeaders(headers)
              break
            }
            case 'devicesNotAssessed': {
              const headers = [
                {
                  key: 'assessmentStatus',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'modelName',
                  label: t(
                    'ecp-global-reports.tableHeader.modelName',
                    'Model Name'
                  )
                },
                {
                  key: 'serialNumber',
                  label: t(
                    'ecp-global-reports.tableHeader.serialNumber',
                    'Serial Number'
                  )
                },
                {
                  key: 'group',
                  label: t('ecp-global-reports.tableHeader.group', 'Group')
                },
                {
                  key: 'notAssessedReason',
                  label: t(
                    'ecp-global-reports.tableHeader.notAssessedReason',
                    'Not Assessed Reason'
                  )
                }
              ]

              setData(
                reportData.map((data) => {
                  let statusName
                  const device = data

                  switch (device?.assessmentStatus?.result) {
                    case 'notAssessed':
                      statusName = t(
                        'ecp-global-reports.risks.notAssessed',
                        'Not Assessed'
                      )

                      break
                    case 'notVerified':
                      statusName = t(
                        'ecp-global-reports.risks.notverified',
                        'Not verified'
                      )

                      break
                    case 'good':
                      statusName = t('ecp-global-reports.risks.good', 'Good')

                      break
                  }

                  return {
                    assessmentStatus: statusName || '',
                    modelName: device?.deviceDetails?.modelname || '',
                    serialNumber: device?.deviceDetails?.serialNumber || '',
                    group: device?.collectionInfo
                      ? device?.collectionInfo[0]?.collectionName
                      : '',
                    notAssessedReason:
                      device?.deviceDetails?.assessmentResultReason || '',
                    // connectivity: '',
                    // credentials: '',
                    // supported: '',
                    // dateAssessed: getDateAndTime(
                    //   device?.assessmentStatus?.lastAssessedTime || null,
                    //   locale
                    // )
                  }
                })
              )

              setHeaders(headers)
              break
            }
            case 'devicesAssessmentSummary': {
              const headers = [
                {
                  key: 'assessmentStatus',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'modelName',
                  label: t(
                    'ecp-global-reports.tableHeader.modelName',
                    'Model Name'
                  )
                },
                {
                  key: 'serialNumber',
                  label: t(
                    'ecp-global-reports.tableHeader.serialNumber',
                    'Serial Number'
                  )
                },
                {
                  key: 'group',
                  label: t('ecp-global-reports.tableHeader.group', 'Group')
                },
                {
                  key: 'dateAssessed',
                  label: t(
                    'ecp-global-reports.tableHeader.dateAssessed',
                    'Date Assessed'
                  )
                }
              ]

              let _addData = []
              reportData.map((device) => {
                let statusName: string
                switch (device?.assessmentStatus?.result) {
                  case 'lowRisk':
                    statusName = t(
                      'ecp-global-reports.risks.lowRisk',
                      'Low Risk'
                    )

                    break
                  case 'mediumRisk':
                    statusName = t(
                      'ecp-global-reports.risks.mediumRisk',
                      'Medium Risk'
                    )

                    break
                  case 'highRisk':
                    statusName = t(
                      'ecp-global-reports.risks.highRisk',
                      'High Risk'
                    )

                    break
                  case 'passed':
                    statusName = t(`ecp-global-reports.risks.passed`, 'Passed')

                    break
                }
                _addData.push({
                  assessmentStatus: statusName || '',
                  modelName: device?.deviceDetails?.modelname || '',
                  serialNumber: device?.deviceDetails?.serialNumber || '',
                  group: device?.collectionInfo
                    ? device?.collectionInfo[0]?.collectionName
                    : '',

                  dateAssessed:
                    getDateAndTime(
                      device?.assessmentStatus?.lastAssessedTime || null,
                      locale
                    ) || ''
                })
              })
              setData(_addData)
              setHeaders(headers)
              break
            }
          }
        } else {
          switch (type) {
            case 'jobSummary': {
              const headers = [
                {
                  key: 'jobName',
                  label: t('ecp-global-reports.tableHeader.jobName', 'Job Name')
                },
                {
                  key: 'deviceDetail',
                  label: t(
                    'ecp-global-reports.tableHeader.deviceDetail',
                    'Device Detail'
                  )
                },
                {
                  key: 'date',
                  label: t('ecp-global-reports.tableHeader.date', 'Date')
                },
                {
                  key: 'status',
                  label: t('ecp-global-reports.status', 'Status')
                },
                {
                  key: 'copies',
                  label: t('ecp-global-reports.tableHeader.copies', 'Copies')
                }
              ]
              setHeaders(headers)
              break
            }
            case 'policyItemsAssessed': {
              const headers = [
                {
                  key: 'policyName',
                  label: 'Policy'
                },
                {
                  key: 'itemName',
                  label: 'Item'
                },
                {
                  key: 'count',
                  label: t('ecp-global-reports.tableHeader.count', 'Count')
                }
              ]

              setHeaders(headers)
              break
            }
            case 'devicesRecommendationSummary': {
              const headers = [
                {
                  key: 'assessmentStatus',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'modelName',
                  label: t(
                    'ecp-global-reports.tableHeader.modelName',
                    'Model Name'
                  )
                },
                {
                  key: 'serialNumber',
                  label: t(
                    'ecp-global-reports.tableHeader.serialNumber',
                    'Serial Number'
                  )
                },
                {
                  key: 'group',
                  label: t('ecp-global-reports.tableHeader.group', 'Group')
                },
                {
                  key: 'recommendations',
                  label: t(
                    'ecp-global-reports.reportsType.devicesRecommendationSummary',
                    'Recommendations'
                  )
                },
                {
                  key: 'dateAssessed',
                  label: t(
                    'ecp-global-reports.tableHeader.dateAssessed',
                    'Date Assessed'
                  )
                }
              ]

              setHeaders(headers)
              break
            }
            case 'devicesRemediationSummary': {
              const headers = [
                {
                  key: 'assessmentStatus',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'modelName',
                  label: t(
                    'ecp-global-reports.tableHeader.modelName',
                    'Model Name'
                  )
                },
                {
                  key: 'serialNumber',
                  label: t(
                    'ecp-global-reports.tableHeader.serialNumber',
                    'Serial Number'
                  )
                },
                {
                  key: 'group',
                  label: t('ecp-global-reports.tableHeader.group', 'Group')
                },
                {
                  key: 'lastRemediated',
                  label: t(
                    'ecp-global-reports.tableHeader.devicesRemediationSummary',
                    'Date Run'
                  )
                }
              ]

              setHeaders(headers)
              break
            }
            case 'devicesNotAssessed': {
              const headers = [
                {
                  key: 'assessmentStatus',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'modelName',
                  label: t(
                    'ecp-global-reports.tableHeader.modelName',
                    'Model Name'
                  )
                },
                {
                  key: 'serialNumber',
                  label: t(
                    'ecp-global-reports.tableHeader.serialNumber',
                    'Serial Number'
                  )
                },
                {
                  key: 'group',
                  label: t('ecp-global-reports.tableHeader.group', 'Group')
                },
                {
                  key: 'notAssessedReason',
                  label: t(
                    'ecp-global-reports.tableHeader.notAssessedReason',
                    'Not Assessed Reason'
                  )
                },
                {
                  key: 'dateAssessed',
                  label: t(
                    'ecp-global-reports.tableHeader.dateAssessed',
                    'Date Assessed'
                  )
                }
              ]

              setHeaders(headers)
              break
            }
            case 'devicesAssessmentSummary': {
              const headers = [
                {
                  key: 'assessmentStatus',
                  label: t(
                    'ecp-global-reports.tableHeader.assessmentStatus',
                    'Assessment Status'
                  )
                },
                {
                  key: 'modelName',
                  label: t(
                    'ecp-global-reports.tableHeader.modelName',
                    'Model Name'
                  )
                },
                {
                  key: 'serialNumber',
                  label: t(
                    'ecp-global-reports.tableHeader.serialNumber',
                    'Serial Number'
                  )
                },
                {
                  key: 'group',
                  label: t('ecp-global-reports.tableHeader.group', 'Group')
                },
                {
                  key: 'dateAssessed',
                  label: t(
                    'ecp-global-reports.tableHeader.dateAssessed',
                    'Date Assessed'
                  )
                }
              ]

              setHeaders(headers)
              break
            }
          }
        }
      })
      .catch((e) => {
        console.log(e)
        addToast({
          id: 'download-single-pdf',
          type: 'negative',
          text: t(
            'ecp-global-reports.toastNotification.downloadingFailureText',
            {
              reportName: fileName,
              defaultValue: `Failed to prepare ${fileName} to download.`
            }
          ),
          timeout: 3
        })
      })
  }

  useEffect(() => {
    if ((data && data.length > 0 && headers.length > 0) || headers.length > 0) {
      excelExport()
      props.setEnableDDownload && props.setEnableDDownload(false)
    }
  }, [data, headers])

  useEffect(() => {
    if (data && data.length == 0) {
      _fetchData()
    }
  }, [])

  return (
    <>
      <ExcelExport
        data={data}
        fileName={fileName + '.xlsx'}
        ref={_export}
        data-testid="reports_Export_Xlsx"
      />
    </>
  )
}
