import React, { useState } from 'react'
import { useI18n } from '@jarvis/react-portal-addons'
import * as Wrapper from './styles'
import { RadioButtons, Checkbox, DatePicker, RadioButton, Select } from '@veneer/core'

export const ScheduleOption = () => {
  const { t } = useI18n()
  const [date, setDate] = useState()
  const handleOnDateChange = (newDate) => {
    setDate(newDate)
  }
  return (
    <Wrapper.SectionContainer>
      <Wrapper.SectionTitle
        id="reports_ScheduleOption_Title"
        data-testid="reports_ScheduleOption_Title"
      >
        {t(
          'ecp-global-reports.generate.scheduleoption.header',
          'Schedule option*'
        )}
      </Wrapper.SectionTitle>

      <Wrapper.SectionTitleDivider />

      <Wrapper.ScheduleOptionRadioButtons
        data-testid="reports_ScheduleOption_RadioButtons"
        name="radiobutton_default"
        defaultValue="0"
      >
        <RadioButton
          data-testid="reports_ScheduleOption_RadioButton_GenerateOnce"
          value="0"
          label={t(
            'ecp-global-reports.generate.scheduleoption.generateText',
            'Generate now and once'
          )}
          disabled
        />
        <RadioButton
          data-testid="reports_ScheduleOption_RadioButton_Generate"
          value="1"
          label={t(
            'ecp-global-reports.generate.scheduleoption.scheduleText',
            'Schedule to generate'
          )}
          disabled
        />
      </Wrapper.ScheduleOptionRadioButtons>

      <Wrapper.scheduleTimeContainer>
        <div style={{display: 'flex'}}>
          <div>
            <DatePicker
              data-testid="reports_ScheduleOption_DatePicker"
              disabled
              label={t(
                'ecp-global-reports.generate.scheduleoption.startDate',
                'Start Date'
              )}
              separateLabel
              placeholder="mm/dd/yyyy"
              onChange={handleOnDateChange}
              value={date}
            />
          </div>
          <div>
            <p style={{ color: '#7B7979' }}>Start Time</p>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ minWidth: '20px' }}>
                <Select
                style={{ minWidth: '75px' }}
                  options={[{ label: '12', value: 12 }]}
                  label="12"
                  disabled
                />
              </div>
              <div style={{ margin: '2px' }}>:</div>
              <div style={{ marginRight: '5px', minWidth: '20px' }}>
                <Select
                 style={{ minWidth: '75px' }}
                  disabled
                  options={[{ label: '10', value: 10 }]}
                  id="select-usage"
                  label="10"
                />
              </div>
              <div style={{ minWidth: '20px' }}>
                <Select
                 style={{ minWidth: '75px' }}
                  options={[{ label: 'PM', value: 'PM' }]}
                  label="PM"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '18px' }}>
          <div>
            <Checkbox
              id="reports_ScheduleOption_Recurrence"
              label={t(
                'ecp-global-reports.generate.scheduleoption.recurrence',
                'Recurrence'
              )}
              disabled
            />
          </div>
        </div>
        <Wrapper.DeviceGroupTimeRadioButton>
          <RadioButtons
            name="radiobutton_default"
            data-testid="reports_ScheduleOption_RadioButtons"
            defaultValue="0"
          >
            <RadioButton
              value="0"
              data-testid="reports_ScheduleOption_RadioButton_Daily"
              label={t(
                'ecp-global-reports.generate.scheduleoption.daily',
                'Daily'
              )}
              disabled
            />
            <div style={{marginLeft: '30px', marginBottom: '10px', color: '#7B7979'}}>
              EveryDay
            </div>
            <RadioButton
              value="1"
              data-testid="reports_ScheduleOption_RadioButton_Weekly"
              label={t(
                'ecp-global-reports.generate.scheduleoption.weekly',
                'Weekly'
              )}
              disabled
            />
            <RadioButton
              value="2"
              data-testid="reports_ScheduleOption_RadioButton_Monthly"
              label={t(
                'ecp-global-reports.generate.scheduleoption.monthly',
                'Monthly'
              )}
              disabled
            />
          </RadioButtons>
        </Wrapper.DeviceGroupTimeRadioButton>
      </Wrapper.scheduleTimeContainer>
    </Wrapper.SectionContainer>
  )
}
